* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "Varela Round";
  }
  
  .dFlex,
  nav,
  nav ul,
  .main_content,
  .main_content .card,
  .main_content .card .card_header,
  .btn,
  .footer,
  .footer .social,
  .footer .social .fa {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px 50px 20px 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
  }
  
  nav {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    color: #fff;
  }
  
  nav .logo {
    font-size: 30px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
  }
  
  nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  nav ul li {
    font-size: 14px;
    margin-left: 15px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
  }
  
  nav .search {
    color: rgba(255, 255, 255, 0.7);
  }
  
  nav .search .fa {
    cursor: pointer;
  }
  
  nav .search .fa + .fa {
    margin-left: 15px;
  }
  
  .main_content {
    position: relative;
    width: 70%;
    height: 100%;
    margin: 50px auto;
    padding: 50px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .main_content h3 {
    position: absolute;
    left: 0;
    top: 0;
    color: #2d343e;
    font-size: 10.5vw;
  }
  
  .main_content .card {
    width: 30%;
    border-radius: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 50px;
  }
  
  .main_content .card .card_img {
    margin-top: -5vw;
  }
  
  .main_content .card .card_img img {
    width: 200px;
  }
  
  .main_content .card .card_header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /* color: #fff; */
    margin: 25px 0;
  }
  
  .main_content .card .card_header h2 {
    font-size: 18px;
  }
  
  .main_content .card .card_header p {
    font-size: 14px;
    text-align: center;
    margin: 8px 0;
  }
  
  .main_content .card .card_header .price {
    font-size: 25px;
    color: #fe8033;
  }
  
  .main_content .card .card_header .price span {
    font-size: 15px;
    display: inline-block;
    vertical-align: top;
  }
  
  .main_content .card:hover {
    /* background-color: #009eff; */
    /* background-image: linear-gradient(#009ffd, #2a2a72); */
    background-image: linear-gradient(#09c6f9, #045de9);
    color: white;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .main_content .card:hover .btn {
    background-color: #fe8033;
    color: #fff;
  }
  
  .btn {
    width: 130px;
    height: 35px;
    font-size: 14px;
    border-radius: 35px;
    background-color: #2d343e;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    position: relative;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    overflow: hidden;
  }
  
  .footer {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: auto;
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;
  }
  
  .footer .social .fa {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 14px;
    color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
  
  .footer .social .fa + .fa {
    margin-left: 15px;
  }
  